/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import githubLogo from '../../static/github.svg';
import linkedinLogo from '../../static/linkedin.svg';

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          social {
            github
            linkedin
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  // const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <Link to="/">
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../images/author.jpg"
          width={110}
          height={110}
          quality={100}
          alt="Profile picture"
        />
        <header className="global-header">
          <h1 className="main-heading">Mateusz Hołowieszko</h1>
        </header>
      </Link>

      <div className="social-links">
        <a href={social.github} target="_blank">
          <img height="32" width="32" src={githubLogo} alt="Github" />
        </a>
        <a href={social.linkedin} target="_blank">
          <img height="32" width="32" src={linkedinLogo} alt="LinkedIn" />
        </a>
      </div>
    </div>
  )
}

export default Bio
